import React from "react"
import { PageProps } from "gatsby"
import { Footer } from "../components/Footer"
import { Navbar } from "../components/Navbar"
import { Category as CategoryEntity } from "../entities/Category"
import { Article } from "../entities/Article"
import Layout from "../components/Layout"
import { Articles } from "../components/Articles"
import { Container } from "../components/Container"
import { Seo } from "../components/Seo"
import configuration from "../configuration/home.json"

const Index: React.FC<
  PageProps<any, { categories: CategoryEntity[]; recentArticles: Article[] }>
> = props => {
  const categories = props.pageContext.categories
  const articles = props.pageContext.recentArticles

  return (
    <Layout>
      <Seo
        title={configuration.seo.title}
        description={configuration.seo.description}
        url={props.location.href}
      />

      <Navbar categories={categories} />

      <Container className="mt-10">
        <Articles articles={articles} featured />
      </Container>

      <Footer categories={categories} />
    </Layout>
  )
}

export default Index
