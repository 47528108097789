import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Category } from "../entities/Category"
import home from "../configuration/home.json"

const socials: {
  name: string
  href: string
  icon: (props: any) => JSX.Element
}[] = []

export const Navbar: React.FC<{
  categories: Category[]
}> = props => {
  const data = useStaticQuery(graphql`
    query NavbarQuery {
      file(relativePath: { eq: "gradient.jpg" }) {
        childImageSharp {
          fixed(jpegQuality: 80, height: 400) {
            src
          }
        }
      }
    }
  `)

  return (
    <>
      <header className="relative bg-black">
        <div className="absolute inset-0">
          <img
            className="object-fill w-full h-full"
            src={data.file.childImageSharp.fixed.src}
          />
        </div>
        <div className="relative z-10 max-w-6xl px-4 mx-auto lg:px-0">
          <nav className="absolute inset-x-0 flex w-full px-4 h-14">
            <div className="flex items-center h-full space-x-6 overflow-auto text-gray-100">
              {props.categories.map(({ title, id, url }) => (
                <Link
                  className="flex-shrink-0 hover:text-white"
                  key={id}
                  to={url}
                >
                  {title}
                </Link>
              ))}
            </div>
            <div className="absolute right-0 w-10 h-full pointer-events-none lg:hidden bg-gradient-to-r from-transparent to-black"></div>
            <div className="flex items-center h-full mx-auto space-x-4 sm:ml-auto sm:mr-0">
              {socials.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  className="text-gray-100 hover:text-white"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="w-6 h-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </nav>
          <div className="w-full py-24 sm:space-y-3 sm:py-32">
            <h1 className="text-4xl font-bold text-center text-white sm:text-5xl">
              {home.title}
            </h1>
            <p className="text-xl font-light text-center text-gray-200 sm:text-2xl">
              {home.description}
            </p>
          </div>
        </div>
      </header>
    </>
  )
}
