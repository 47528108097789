import React from "react"
import { Link } from "gatsby"
import { Category as CategoryEntity } from "../entities/Category"
import { Article } from "../entities/Article"
import { Obfuscated } from "./Obfuscated"
import { GatsbyImage, getImage, getImageData } from "gatsby-plugin-image"

const Title: React.FC<{
  url: string
  title: string
  small: boolean
}> = props => (
  <h2>
    <Link
      to={props.url}
      className={`${
        props.small ? "text-xl lg:text-2xl" : "text-xl lg:text-3xl"
      } block mt-1 font-bold text-black`}
    >
      {props.title}
    </Link>
  </h2>
)

const Author: React.FC<{
  date: string
  small: boolean
  image: string
  name: string
}> = props => {
  return (
    <footer
      className={`${props.small ? "mt-4" : "mt-4 lg:mt-8"} flex items-center`}
    >
      <section className="flex-shrink-0">
        <div>
          <span className="sr-only">{props.name}</span>
          <img
            className="w-8 h-8 rounded-full"
            src={props.image}
            alt={`image de profil de ${props.name}`}
          />
        </div>
      </section>

      <section className="ml-3">
        <p className="text-sm font-medium text-gray-900">{props.name}</p>

        <time
          className="flex space-x-1 text-sm text-gray-700"
          dateTime={props.date}
        >
          Publié le {props.date}
        </time>
      </section>
    </footer>
  )
}

const Description: React.FC<{ small: boolean }> = props => (
  <p
    className={`${
      props.small ? "text-base" : "text-base lg:text-lg"
    } lg:mt-2 font-light text-gray-500`}
  >
    {props.children}…
  </p>
)

const Category: React.FC<{
  category: Pick<CategoryEntity, "url" | "title">
}> = props => (
  <Obfuscated
    should
    to={props.category.url}
    className="block text-xs font-medium text-black uppercase"
  >
    {props.category.title}
  </Obfuscated>
)

const Cover: React.FC<{
  illustration: any
  small: boolean
  url: string
  title: string
}> = props => {
  const image = getImage(props.illustration)

  return (
    <Link to={props.url} className="block lg:col-span-2" title={props.title}>
      <GatsbyImage
        className={`object-cover w-full rounded h-48 lg:h-48`}
        alt={props.title}
        image={image}
      />
    </Link>
  )
}

const Grid: React.FC = props => (
  <div className="gap-8 pb-8 space-y-8 md:grid md:grid-cols-2 md:space-y-0 lg:grid-cols-3">
    {props.children}
  </div>
)

const Meta: React.FC<{ small: boolean }> = props => (
  <section
    className={`${
      props.small ? "pt-6" : "pt-6 lg:mt-0 items-center"
    } w-full flex flex-row h-full col-span-1 lg:my-auto`}
  >
    <div>{props.children}</div>
  </section>
)

export const Articles: React.FC<{
  articles: Article[]
  featured?: boolean
}> = ({ articles, featured }) => {
  return (
    <Grid>
      {articles.map((article, index) => (
        <section key={article.url} className={`flex flex-col`}>
          <Cover {...article} small />
          <Meta small>
            <Category category={article.category} />
            <Title {...article} small />
            <Description small>
              {article.description.slice(0, 120).trim()}
            </Description>
            <Author
              small
              name={article.author.name}
              image={article.author.image}
              date={article.created_at}
            />
          </Meta>
        </section>
      ))}
    </Grid>
  )
}
